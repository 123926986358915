body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Survey top navigation styling */
ul.sv_progress-buttons__list li.sv_progress-buttons__list-element--current::before {
  border-color: #419dff;
}
ul.sv_progress-buttons__list li::before {
  border-color: #92c5fc;
  background-color: #92c5fc;
}
ul.sv_progress-buttons__list li.sv_progress-buttons__list-element--passed::before {
  border-color: #419dff;
  background-color: #419dff;
}
ul.sv_progress-buttons__list li.sv_progress-buttons__list-element--passed + li::after {
  background-color: #92c5fc;
}
ul.sv_progress-buttons__list li.sv_progress-buttons__list-element--passed.sv_progress-buttons__list-element--current::before {
  border-color: #419dff;
}